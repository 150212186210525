.contents_login{
	background: #C2D8DF;
	height: 100vh;
	.login_all{
		min-width: 250px;
		margin: 0px auto;
		text-align: center;
		padding: 30px;
		background: #fff;
		border-radius: 6px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.login_item{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			dt {
				font-weight: 600;
				padding-right: 15px;
				padding-top: 15px;
				width: 30%;
			}
			dd{
				width: 70%;
				padding-top: 15px;
				input{
					padding: 8px;
					border: 1px solid #bbbbbb;
					font-size: 100%;
					width: 100%;
				}
			}
		}
		.login_btn{
			width: 50%;
			margin: 20px auto 0px auto;
			padding: 10px;
		}
	}
}
@media screen and (max-width: 480px){
	.contents_login{
		.login_all{
			width: 80%;
			.login_item{
				display: block;
				dt,dd{
					width: 100%;
				}
			}
			.login_btn{
				width: 100%;
			}
		}
	}
}