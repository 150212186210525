.date_head{			
	.month{
		width: 80%;
	}
}
.time_add{
	background: #ffffff;
	padding: 15px;
    position: absolute;
    width: 30%;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	margin: auto;
	dl{
		display: flex;
		flex-wrap: wrap;
		dt{
			width: 20%;
			font-weight: 600;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
		}
		dd{
			width: 80%;
			margin-bottom: 10px;
			input{
				padding: 8px;
				border: 1px solid #bbbbbb;
				width: 40%;
			}
		}
	}
	.time_decision_btn{
		background: #F97C04;
		width: 45%;
		padding: 5px;
	}
}
.time_edit{
	background: #ffffff;
	position: absolute;
	padding: 15px;
	width: 50%;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	margin: auto;
	div{
		display: flex;
		align-items: center;
		input.btn_cancel{
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			background: transparent;
			border: none;
			border-radius: 0;
			outline: none;
			cursor: pointer;
			width:30px;
			padding-left: 0px; 
			padding-right: 0px;
			margin-right: 10px;
			z-index:10;
		}
		i.fas.fa-times-circle{
			position: absolute;
			cursor: pointer;
			margin-top: -10px;
		}
		dl{
			display: flex;
			dt{
				width: 20%;
				font-weight: 600;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
			}
			dd{
				width: 80%;
				margin-bottom: 10px;
				input{
					padding: 8px;
					border: 1px solid #bbbbbb;
					width: 40%;
				}
			}
		}
	}
	.time_decision_btn{
		background: #F97C04;
		width: 45%;
		padding: 5px;
	}
}
.schedule_list{
	border: 1px solid #d7d7d7;
	border-bottom: none;
	text-align: left;
	margin-top: 25px;
    div{
        display: flex;
        flex-wrap: wrap;
        dt{
            font-weight: 600;
            padding: 5px;
            width: 10%;
            border-bottom: 1px solid #d7d7d7;
            border-right:1px solid #d7d7d7;
            display: flex;
            align-items: center;
        }
        dd{
            padding: 5px;
            width: 90%;
            border-bottom: 1px solid #d7d7d7;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
	.item_time{
		li{
			display: flex;
			line-height: 1.4em;
			align-items: center;
			div{
				display: flex;
			}
			.order{
				margin-left: 10px;
				background: #ff0000;
				border-radius: 6px;
				padding: 3px;
				color: #ffffff;
				font-size: 80%;
			}
		}
	}
	.item_btn{
		display: flex;
		.btn_sdl_edit{
			margin-left: 10px;
		}
	}
}
.v--modal-overlay .v--modal-box {
    position: inherit!important;
}
.v--modal {
    background-color: transparent!important;
    text-align: center!important;
    box-shadow: none!important;
}
@media screen and (max-width: 480px){
	.schedule{
		.date_head{
			.month{
				width: 100%;
			}
			.back{
				display: none;
			}
		}
	}
	.time_add{
		width: 80%;
	}
	.time_edit{
		top:320px;
		width: 80%;
	}
	.schedule_list{
        font-size: 80%;
        div{
            dt{
                width: 22%;
            }
            dd{
                width: 78%;
            }
        }
	}
}