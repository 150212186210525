.date_head{
	.date_title{
		margin: 0px auto;
		text-align: center;
		padding-top:10px;
		font-weight: 600;
	}
}
.search_area{
	display: flex;
	justify-content: space-between;
	padding-top:25px;
	.searchbox{
		width: 40%;
		position: relative;
		input{
			padding: 8px;
			padding-right: 10%;
			border: 1px solid #bbbbbb;
			font-size: 100%;
			width: 100%;
		}
		a{
			display: block;
			position: absolute;
			right: 0px;
			top:10px;
			width: 10%;
			.fa-search{
			}
		}
	}
	.dr_add_btn{
		width: 10%;
		background:#F97C04;
	}
}
.dr_list{
	margin-top: 25px;
	dl{
		border: 1px solid #d7d7d7;
		dt ul{
			font-weight: 600;
			background: #f1f1f1;
		}
		dt ul, dd ul{
			display:flex;
			li{
				width: (100%/6);
				text-align: left;
				padding: 10px;
				border-right: 1px solid #d7d7d7;
				border-bottom: 1px solid #d7d7d7;
				display : table-cell; 
				word-wrap : break-word;
				overflow-wrap : break-word;
				line-height: 1.4em;
				img{
					width: 18px;
					margin-left: 10px;
					vertical-align: middle;
				}
			.action{
					display: flex;
				width: 100%;
					li{
						width: (100%/3);
						border: none;
						padding:0px 5px 0px 0px;
						.action_btn{
							width: 100%;
						}
					}
				}
			}
			li:first-child{
				width: 10%;
			}
			li:nth-child(2) a{
				text-decoration: underline;
				color: #337ac6;
			}
			li:last-child{
				width:26.2%;
				border-right: none;
				padding-right: 0px;
			}
		}
	}
}

	

