.cancel_btn{
	position: absolute;
    top: 0px;
    right: 0px;
	width: 15%;
	background: #F97C04;
}
.surgery_add{
	margin-top: 30px;
	ul{
		display: flex;
		li{
			width:(100%/2);
			border-left:5px solid #d7d7d7;
			padding: 0px 10px;
			dl{
				display:flex;
				flex-wrap: wrap;
				font-size: 80%;
				text-align: left;
				align-items: center;
			dt{
				width: 30%;
				line-height: 1.6em;
				margin-top: 0px;
				font-weight: 600;
				&:after{
					content: ".";
					width: 0;
					font-size: 0;
					color: transparent;
					margin-right: 1px;
				}
			}
			dd {
				width: 70%;
				text-indent: -0.3em;
				padding-left: 0.5em;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
				input{
					padding: 8px;
					border: 1px solid #bbbbbb;
					font-size: 100%;
					width: 100%;
				}
				textarea{
					width: 100%;
					height: 50px;
				}
			}
			dd::before {
				content: ":";
				font-weight: 600;
				padding-right: 5px;
				margin-left: -5px;
				}
			}
		}
	}
}
.before_surgery{
	margin-top: 25px;
	.title{
		text-align: left;
		border-left: 5px solid #EF38D0;
		padding: 0px 10px;font-weight: 600;
	}
	ul{border-left:5px solid #d7d7d7;
		.bs_item{
			margin-top: 25px;
			display: flex;
			flex-wrap: wrap;
			font-size: 80%;
			text-align: left;
			align-items: center;
			dt{
				width: 8%;
				line-height: 1.6em;
				margin-top: 0px;
				font-weight: 600;
				padding-left: 10px;
			}
			dd {
				text-indent: -0.3em;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
			}
			.comment{
				width: 90%;
				textarea{
					width: 100%;
					height: 100px;
				}
			}
			.item_select{
				width: 12%;
				div{
					display: flex;
					justify-content: flex-start;
					width: 100%;
					align-items: center;
					p{
						width: 30%;
						padding-right: 5px;
					}
				}
			}
			.item_detail{
				width: 75%;
				padding-left: 15px;
				.item_check{
					display: flex;
					width:(100%/4);
					div{
						display: flex;
						align-items: center;
						input{
							width: auto;
							margin-right: 10px;
						}
						p{
							line-height: 1.4em;
							word-break: break-word;
							white-space: nowrap;
						}
					}
				}
			}
			input{
				padding: 8px;
				border: 1px solid #bbbbbb;
				font-size: 100%;
				width: 100%;
			}
		}
	}
}
