.dr_change_btn{
	padding:5px 20px;
	margin-left: 10px;
	background: #F97C04;
}
.info_add_patient{
	display: flex;
	width: 100%;
	margin-top: 25px;
	.add_item{
		width: 50%;
		.title{
			text-align: left;
			border-left: 5px solid #EF38D0;
			padding: 0px 10px;font-weight: 600;
		}
		dl{
			margin-top: 20px;
			display: flex;
			flex-wrap: wrap;
			font-size: 80%;
			text-align: left;
			border-left: 5px solid #d7d7d7;
			padding: 0px 10px;
			align-items: center;
			dt{
				width: 30%;
				line-height: 1.8em;
				margin-top: 0px;
				font-weight: 600;
			}
			dd {
				width: 70%;
				text-indent: -0.3em;
				padding-left: 0.5em;
				display: flex;
				align-items: center;
				input{
					padding: 8px;
					border: 1px solid #bbbbbb;
					font-size: 100%;
					width: 100%;
					margin-top: 5px;
					margin-bottom: 5px;
					}
				textarea{
					width: 100%;
					height: 50px;
					margin-top: 5px;
				}
				span{
					padding-left: 15px;
				}
			}
			dd::before {
				content: ":";
				font-weight: 600;
				padding-right: 5px;
				margin-left: -5px;
			}
		}
		.btn_kiroku{
			padding: 5px 20px;
			background: #F97C04;
			margin-top: 20px;
			display: flex;
		}
	}
}

@media screen and (max-width: 480px){
	.contents_surgery_eddit{
		.srl_head{
			.back{
				display: none;
			}
			.edit_btn{
				display: none;
			}		
			.request_number{
				display: block;
				text-align: left;
				li{
					padding-bottom: 7px;
				}
				li:last-child {
					padding-left: 0px;
				}
			}
		}
		.info_patient{
			i{
				display: none;
			}
			ul{
				display:block;
				li{
					width: 100%;
					dl{
						font-size: 100%;
						align-items: center;
						dt{
							width: 35%;
						}
						dd{
							width: 65%;
							.dr_change_btn{
								margin-left: 0px;
								margin-top: 5px;
							}
						}
					}
				}
			}
		}
		.info_add_patient{
			display: block;
			.add_item{
				width: 100%;
				dl{
					font-size: 100%;
					dt{
					width: 35%;
					}
					dd{
					width: 65%;
					}
				}
			}
		}
	}
}