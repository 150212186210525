header{
	background: #337ac6;
	padding: 10px 20px;
	width: 100%;
	position: fixed;
	display: flex;
	align-items: center;
	min-height: 60px;
	z-index: 1000;
	.title{
		color: #ffffff;
		font-weight: 600;
		width: 25%;
	}
	nav ul{
		display: flex;
		background: #ffffff;
		border: 1px solid #d7d7d7;
		li{
			padding: 10px;
			border-right: 1px solid #d7d7d7;
			a{
				display: block;
			}
		}
		:last-child{
			border-right:none;
		}
		.stay{
			background: #f7f891;
		}
	}
	.user{
		display: flex;
		margin-left:auto;
		position: relative;
		color: #ffffff;
		align-items: center;
		.sp_menu{
			display: none;
		}
		.status{
			display: flex;
			padding-right: 15px;
			color: #ffffff;
			align-items: center;
			li{
				padding-left: 10px;
			}
			li:first-child::after{
				content: "：";
			}
			.fa-angle-down{
				color: #ffffff;
			}
			.fa-user{
				display: none;
			}
		}
		.fa-bell{
			font-size: 150%;
		}
		.unread{
			position:absolute;
			z-index: 999;
			top:-5px;
			right:0px;
			font-size: 40%;
			text-align: center;
			color: #ffffff;
			padding: 3px;
			background: #ff0000;
			min-width: 15px;
			height: 15px;
			line-height: 9px;
			border-radius: 50%;
		}
	}
}
.tooltip{
    position: relative;
    cursor: pointer;
    display: inline-block;
	p{
		margin:0;
		padding:10px;
	}
	.description{
		display: none;
		position: absolute;
		background: #ffffff;
		padding: 10px;
		font-size: 12px;
		line-height: 1.6em;
		color: #000000;
		border-radius: 5px;
		width: 100px;
		border: 1px solid #d7d7d7;
		p{
			display: none;
		}
	}
	.description:before {
		content: "";
		position: absolute;
		top: -24px;
		right: 0%;
		border: 15px solid transparent;
		border-top: 15px solid #ffffff;
		margin-left: -15px;
		transform: rotateZ(180deg);
	}
}
.tooltip:hover .description{
    display: inline-block;
    top: 40px;
    right: -10px;
}
@media screen and (min-width: 480px){
	.sp_new {
		display: none;
	}
}
@media screen and (max-width: 480px){
	#sidebar{
        position: relative;
        z-index: 99;
		#menu-wrap.is-active{
			opacity: 1;
			visibility: visible;
			right: 0;
		}
		.scd_new{
			width: 80%;
			height: 70vh;
            background: white;
			overflow: scroll;
		}
	}
	header{
		.title{
			display: none;
		}
		.login_title{
			display: block;
		}
		.user{
			.sp_menu{
				display: block!important;
			}
			.pc_menu{
				display: none;
			}
		}
		.status{
			li:nth-child(-n+2){
				display: none;
			}
			li:nth-child(3) i.fa-angle-down{
				display: none;
			}
			li:nth-child(3) i.fa-user{
				display: block;
				color: #000000;
			}
		}
	}
	.tooltip{
		.description{
			width: 150px;
			p{
				display: block;
			}
		}
	}
}

#notification {
  transition: .3s ease-in-out;
  display: none;
  /*
  -webkit-transform: translateX(205%);
  transform: translateX(205%);
  */
}
#notification-switch:checked ~ #notification {
  display: block;
  /*
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  */
}
#sidebar {
    width: 1px;
    overflow: hidden;
}
#notification-switch-sp-sidebar:checked ~ #sidebar {
    width: auto;
    overflow: visible;
}
#notification-sp {
  transition: .3s ease-in-out;
  display: none;
  /*
  -webkit-transform: translateX(105%);
  transform: translateX(105%);
  */
}
#notification-switch-sp:checked ~ #notification-sp {
  display: block;
    /*
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  */
}
#sp_menu_header {
    width: 100vw;
}

body {
    width: 100vw!important;
    overflow-x: hidden!important;
}
