html{
	font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}
html,body{
	height: 100%;
}
.wrapper{
	position: relative;
}
.mrg_r5{
	margin-right: 5px;
}
.mrg_t20{
	margin-top: 20px;
}
.pdg_tb0{
	padding-top:0px!important;
	padding-bottom: 0px!important;
}
.pdg_r5{
	padding-right: 5px;
}
.pdg_15{
	padding:15px!important;
}
.w_15{		
	width: 15%!important;
}
.w_25{
	width: 25%!important;
}
.w_50{
	width: 50%!important;
}
.w_100{
	width: 100%!important;
}
.w_21{
	width:21.5%!important;
}
@-moz-document url-prefix() {
	.ff_w_22{
		width: 22%!important;
	}
}
.contents{
	margin: 0px auto;
	margin-bottom: 25px;
	text-align: center;
	width:95%;
	padding-top:75px;
	/*background:#F3F3F3;*/
	.date_head{
		position: relative;
		.btn{
			position: absolute;
			top:0px;
			right: 0px;
			width: 10%;
			background:#F97C04;
		}
		.back{
			position: absolute;
			top:0px;
			left: 0px;
			padding: 10px 0px;
		}
		.back::before{
			font-family: "Font Awesome 5 Free";
			content:'\f053';
			font-weight: 900;
		}
	}
}
.txt_small{
	font-size: 60%;
}
.txt_blue{
	color: #337ac6;
}
.txt_red{
	color: #ff0000;
}
.btn{
	text-align: center;
	background: #337ac6;
	border-radius: 6px;
	color: #ffffff;
	font-weight: 600;
	font-size: 100%;
	border: none;
	-webkit-appearance: none;
	cursor: pointer;
	a{
		display: block;
		color: #ffffff!important;
		font-weight: 600;
		text-decoration: none!important;
		padding: 10px;
	}
}
div.btn{
	padding: 0px;
}
.check_btn{
	label {
		line-height: 135%;
		position: relative;
		cursor: pointer;
		.option-input {
			position: relative;
			margin: 0 1rem 0 0;
			cursor: pointer;
		}
		.option-input:before {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0.125rem;
			width: 0.75rem;
			height: 0.75rem;
			content: '';
			-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
					transition:         transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
			-webkit-transform: scale(0, 0);
					transform: scale(0, 0);
			border-radius: 50%;
			background: #337ac6;
		}
		.option-input:checked:before {
			-webkit-transform: scale(1, 1);
					transform: scale(1, 1);
		}
		.option-input:after {
			position: absolute;
			top: -0.25rem;
			left: -0.125rem;
			width: 1rem;
			height: 1rem;
			content: '';
			border: 2px solid #666666;
			border-radius: 50%;
			background: #ffffff;
		}
	}
}


.pager{
	margin-top: 25px;
	 ul.pagination {
		 text-align: center;
		  margin: 0;
		  padding: 0;
		 li {
			 display: inline;
			 margin: 0 2px;
			 padding: 0;
			 display: inline-block;
			 background:#ffffff;
			 border: 1px solid #337ac6;
			 width: 30px;
			 height: 30px;
			 font-size: 60%;
			 text-align: center;
			 position: relative;
			 a{
				 vertical-align: middle;
				 position: absolute;
				 top: 0;
				 left: 0;
				 width: 100%;
				 height: 100%;
				 text-align: center;
				 display:table;
				 color: #337ac6;
				 text-decoration: none;
				 span{
					 display:table-cell;
					 vertical-align:middle;
				 }
			 }
			 a:hover,a.active{
				 color: #ffffff;
				 background: #337ac6;
			 }
		 }

	}
}
.pink{
	background:#FFEAEB;
}
.lightgreen{
	background: #b8eba2;
}
.gray{
	background: #f1f1f1;
}
.blue_bdr{
	border-left:5px solid #337ac6!important;
}
.green_bdr{
	border-top:1px solid #338F00!important;
	border-bottom:1px solid #338F00!important;
}
.green_bdr_l{
	border-left:1px solid #338F00!important;
}
.green_bdr_r{
	border-right:1px solid #338F00!important;
}
.errorlist{
	color: #ff0000;
	font-size: 80%;
}
.error_msg {
    color:red;
    margin-top: 20px;
}
.month{
	margin: 20px auto 0px auto;
	ul{
		display: flex;
		justify-content: space-between;
		li:first-child::before{
			font-family: "Font Awesome 5 Free";
			content:'\f053';
			font-weight: 900;
		}
		li:nth-child(2){
			font-weight: 600;
		}
		li:last-child::after{
			font-family: "Font Awesome 5 Free";
			content:'\f054';
			font-weight: 900;
		}
	}
}
.cp_ipselect {
	overflow: hidden;
	width: 100%;
	text-align: center;
	select {
		width: 100%;
		padding-right: 1em;
		cursor: pointer;
		text-indent: 0.01px;
		text-overflow: ellipsis;
		border: none;
		outline: none;
		background: transparent;
		background-image: none;
		box-shadow: none;
		-webkit-appearance: none;
		appearance: none;
	}
	select::-ms-expand {
		display: none;
	}
}
.cp_sl01 {
	position: relative;
	border: 1px solid #bbbbbb;
	border-radius: 2px;
	background: #ffffff;
}
.cp_sl01::before {
	position: absolute;
	top: 0.8em;
	right: 0.9em;
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #666666;
	pointer-events: none;
}
.cp_sl01 select {
	padding: 8px 38px 8px 8px;
	color: #000000;
}
textarea{
	border: 1px solid #d7d7d7;
}

/*checkbox*/
.checkbox-input{
  display: none;
}
.checkbox-parts{
  padding-left: 25px;
  position:relative;
  margin-right: 20px;
}
.checkbox-parts::before{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #999;
  border-radius: 4px;
}
.checkbox-input:checked + .checkbox-parts{
  color: #337ac6;
}
.checkbox-input:checked + .checkbox-parts::after{
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 5px;
  width: 7px;
  height: 14px;
  transform: rotate(40deg);
  border-bottom: 3px solid #337ac6;
  border-right: 3px solid #337ac6;
}







@media screen and (max-width: 480px){
	.sp_mrg_t25{
		margin-top:25px;
	}
	.sp_w_100{
		width:100%!important;
	}
	.sp_w_35{
		width:35%!important;
	}
    body {
        min-height: 100vh;
    }
}

.alert-overlay {
    animation: show 0.07s linear 0s;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    opacity: 100;
}
.alert-overlay.hide{
    display: none;
    opacity: 0;
}
.alert-message {
    text-align: center;
    color: black;
    padding: 20px;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 40vh;
    width: 80%;
    background: white;
}
.alert-title {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}
@keyframes show{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
