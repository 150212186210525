.srl_head{
	position: relative;
	.back{
		position: absolute;
		top:0px;
		left: 0px;
		padding: 10px 0px;
	}
	.back::before{
		font-family: "Font Awesome 5 Free";
		content:'\f053';
		font-weight: 900;
	}
	.request{
		margin: 0px auto;
		text-align: center;
		padding: 10px 0px;
		.request_number{
			display: flex;
			justify-content: center;
			font-weight: 600;
			li:first-child::after{
			content:'：';
			}	
			li:last-child{
				padding-left: 30px;
			}
		}
	}
	.edit_btn{
		background: #F97C04;
		position: absolute;
		top:0px;
		right: 0px;
		width: 10%;
	}
	.delete_btn{
		background: #F97C04;
		position: absolute;
		top:0px;
		right: 12%;
		width: 10%;
	}
	@media screen and (max-width: 480px){
		.delete_btn{
			right: 0px;
			width: 30%;
		}
	}
}

.info_patient{
	margin-top: 30px;
	.fa-file{
		position: absolute;
		right: 2.5%;
		font-size: 150%;
	}
	ul{
		display: flex;
		li{
			width:(100%/4);
			border-left:5px solid #d7d7d7;
			padding: 0px 10px;
			dl{
				display:flex;
				flex-wrap: wrap;
				font-size: 80%;
				text-align: left;
				align-items: center;
			dt{
				width: 45%;
				line-height: 1.6em;
				margin-top: 0px;
				font-weight: 600;
			}
			dd {
				width: 55%;
				text-indent: -0.3em;
				padding-left: 0.5em;
				img{
					width: 18px;
					vertical-align: middle;
					margin-left: 10px;
				}
			}
			dd::before {
				content: ":";
				font-weight: 600;
				padding-right: 5px;
				margin-left: -5px;
				}
			}
		}
	}
	.hidden_box {
		margin: 35px auto;
		padding: 0;
		label{
			padding: 10px;
			font-weight: bold;
			border: solid 1px #000;
			cursor :pointer;
			border-radius: 6px;
			position: relative;
			z-index: 600;
		}
		input {
			display: none;
		}
		.hidden_show {
			height: 0;
			width: 100%;
			padding: 0;
			overflow: hidden;
			position: absolute;
			background: #ffffff;
			z-index: 500;
			opacity: 0;
			left: 0px;
			/*transition: 0.8s;*/
			.add_info{
				width: 95%;
				margin: 20px auto;
				text-align: center;
				.add_title{
					text-align: left;
					font-weight: 600;
					border-left: 5px solid #337ac6;
					padding-left: 10px;
				}
				ul{
					margin-top: 15px;
				}
			}
		}
		input:checked ~ .hidden_show {
			padding: 10px 0 35px 0;
			height: auto;
			opacity: 1;
			border-bottom: 1px solid #000000;
		}
	}
}


.reservation{
	display: flex;
	flex-wrap: wrap;
	border:1px solid #d7d7d7;
    max-height: 350px;
    overflow-y: scroll;
	dt{
		width: 20%;
		border-right:1px solid #d7d7d7;
		border-bottom: 1px solid #d7d7d7;
	}
	.reservation_name{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 10px;
	}
	dd{
		width: 80%;
	}
	.reservation_time{
		display: flex;
		li{
			width:(100%/13);
			border-bottom: 1px solid #d7d7d7;
			text-align: center;
			padding: 0px;
			min-height: 36px;
			ul.minute{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				li{
					width: (100%/6);
					padding: 0px;
					border: none;
					display: flex;
					align-items: center;
					span{
						position: relative;
						z-index: 99;
					}
				}
			}
		}
		li:last-child{
			border-right: none;
		}
	}
}
.list_header{
	height: auto;
	border-bottom: none;
	dt{
		border-bottom: none;
		padding: 10px;
	}
	dd{
		position: relative;
		.reservation_time{
			li{
				padding: 10px;
				border-right:1px solid #d7d7d7;
			}
		}
		.time_position{
			position: relative;
			z-index: 10;
		}
		.time_bg{
			position: absolute; 
			top:0px; 
			width: 100%;
			li{
				padding: 0px;
			}
		}
	}
	.reservation_time{
		li{
			border-bottom: none;
		}
	}
}
.last_time{
	border-bottom: none!important;
	li{
		border-bottom: none!important;
	}
}
.dr_decision_btn{
	background: #F97C04;
	margin: 25px auto 0px auto;
	width: 15%;
	padding: 10px;
}