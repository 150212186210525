.scd_new{
	position: absolute;
	width: 20%;
	background: #ffffff;
	border: 1px solid #d7d7d7;
	right:0px;
	top: 60px;
	padding: 10px;
	text-align: left;
	line-height: 1.6em;
	z-index: 100;
	.title{
		font-weight: 600;
	}
	.new_detail{
		font-size: 90%;
		margin-top: 10px;
	}
	.date{
		font-weight: 600;
	}
	.item{
		border: 1px solid #d7d7d7;
		padding: 10px;
		border-radius: 6px;
		align-items: center;
		position: relative;
		div::after{
			font-family: "Font Awesome 5 Free";
			content:'\f054';
			font-weight: 900;
			position: absolute;
			right:5px;
			top:50%;
		}
		.time_scd{
			font-weight:600;
			border-bottom: 1px solid #4e7c45;
			padding-bottom: 5px;
		}
		ul{
			display:flex;
			flex-wrap: wrap;
			font-size: 80%;
			margin-top: 5px;
			margin-bottom: 15px;
			width: 90%;
			li{
				line-height: 1.6em;
				margin-top: 0px;
			}
			li:nth-child(odd) {
				font-weight: 600;
				width: 30%;
			}
			li:nth-child(even) {
				width: 70%;
				text-indent: -0.3em;
				padding-left: 0.5em;
			}
			li:nth-child(even)::before {
				content: ":";
				font-weight: 600;
				padding-right: 5px;
				margin-left: -5px;
			}
		}
		.date_sent{
			position: absolute;
			bottom: 0px;
			right: 5px;
		}
	}
	.item:nth-child(n+2){
		margin-top: 5px;
	}
}
.contents_scd{
	margin: 0px auto;
	text-align: center;
	padding-top: 60px;
	.scd_all{
		display: flex;
		position: relative;
		.charge{
			position: absolute;
			left: 26%;
			top:25px;
			ul{
				display: flex;
				justify-content: flex-start;
				li:first-child::after{
					content: "/";
					font-weight: 300;
				}
				.stay{
					font-weight: 600;
				}
			}
		}
		.buttons{
			position: absolute;
			right: 1%;
            top: 15px;
            .add_btn{
                background: #F97C04;
				a{
					padding: 5px;
				}
            }
        }
		.scd_detail{
			width:25%;
			border-right:1px solid #d7d7d7;
			height: 100vh;
			padding: 15px;
			padding-bottom: 75px;
			text-align: left;
			overflow: scroll;
			-webkit-overflow-scrolling: auto;
			overflow-scrolling: auto;
			.detail{
				li:nth-child(n+2){
					margin-top: 15px;
				}
					.date{
						font-weight: 600;
					}
					.item{
						margin-top: 10px;
						padding: 10px;
						border-radius: 6px;
						align-items: center;
						position: relative;
						div::after{
							font-family: "Font Awesome 5 Free";
							content:'\f054';
							font-weight: 900;
							position: absolute;
							right:5px;
							top:50%;
						}
							.time_scd{
								font-weight:600;
								border-bottom: 1px solid #4e7c45;
								padding-bottom: 5px;
							}
							ul{
								display:flex;
								flex-wrap: wrap;
								font-size: 80%;
								margin-top: 5px;
								width: 90%;
								li{
									line-height: 1.6em;
									margin-top: 0px;
								}
								li:nth-child(odd) {
									font-weight: 600;
									width: 30%;
								}
								li:nth-child(even) {
									width: 70%;
									text-indent: -0.3em;
									padding-left: 0.5em;
								}
								li:nth-child(even)::before {
									content: ":";
									font-weight: 600;
									padding-right: 5px;
									margin-left: -5px;
								}
							}
						}
					.done{
						background: #d7d7d7;
					}
					.decided{
						background: #b8eba2;
					}
					.undecided{
						background: #f7f891;
					}
			}
		}
		.scd_calendar{
			width: 75%;
			padding: 40px 20px;
			.month{
				margin-top: 20px;
			}
			.calendar{
				width:100%;
				display: flex;
				flex-wrap: wrap;
				border:1px solid #d7d7d7;
				margin-top: 20px;
				height: 75vh;
				li{
					width:(100%/7);
					border-right:1px solid #d7d7d7;
					border-bottom:1px solid #d7d7d7;
					.day{
						font-size: 70%;
						font-weight: 600;
						text-align: center;
						padding: 5px;
					}
					ul.scd{
						li{
							border-radius: 100px;
							padding: 2px;
							width: 90%;
							text-align: left;
							margin: 3px auto 0px auto;
							color: #000;
							font-size: 50%;
							border:none;
							a{
								display: block;
							}
						}
						.done{
							background: #d7d7d7;
						}
						.decided{
							background: #b8eba2;
						}
						.undecided{
							background: #f7f891;
						}
						.more{
							margin: 0px 15px;
						}
					}
				}
				li:nth-child(-n+7){
					text-align: center;
					font-size: 80%;
					font-weight: 600;
					padding: 5px;
				}
				li:nth-last-child(-n+7){
					border-bottom:none;
				}
				li:nth-child(7n){
					color: #0059AF;
				}
				li:nth-child(7n+1){
					color: #ff0000;
				}
				li:nth-child(n+8){
					min-height: 80px;
				}
				li.today{
					border: 1px solid #ff0000;
					background-color: rgba(255,0,4,.1)
				}
			}
		}
	}
}



@media screen and (max-width: 480px){
	.wrapper{
		.scd_detail{
            display: none;
		}
		#schedulelist-switch:checked ~ .scd_detail{
            display: block;
            width: 80%!important;
            position: absolute;
            z-index: 1;
            background: white;
		}
		.pc_new{
			display: none;
		}
	}
	.contents_scd{
		.scd_all{
			.charge{
				left: 20px;
			}
			.scd_calendar{
				width: 100%;
				.calendar{
					.scd{
						li {
							font-size: 50%;
							text-align: left;
						}
					}
				}
			}
		}
	}
}
