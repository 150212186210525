.srl_head{
	.date_title{
		padding: 10px 0px;
		font-weight: 600;
	}
}
	
.patient_add{
	dl{
		display: flex;
		flex-wrap: wrap;
		border: 1px solid #d7d7d7;
		border-bottom: none;
		margin-top: 25px;
		font-size: 100%;
		dt {
			font-weight: 600;
			width: 30%;
			background: #f1f1f1;
			display: flex;
			align-items: center;
			padding: 5px;
			border-righth: 1px solid #d7d7d7;
			border-bottom: 1px solid #d7d7d7;
		}
		dd{
			width: 70%;
			padding: 10px;
			border-bottom: 1px solid #d7d7d7;
			display: flex;
			align-items: center;
			.cp_ipselect select{
				font-size: 100%;
			}
			input{
				padding: 8px;
				border: 1px solid #bbbbbb;
				font-size: 100%;
				margin-right: 5px;

			}
			.check_btn{
				label{
					display: flex;
					padding: 5px;
					div{
						display: flex;
						align-items: center;
						margin-right: 25px;
					}
				}
			}
			select {	
				border: 1px solid #d7d7d7;	
				padding: 8px;		
				border-radius: 0;
				background: #ffffff;	
				font-size: 100%;
			}
		}
	}
}
